const YEAR = new Date().getFullYear();

const TEXTS = {
  cardHeader: {
    en: "Data from the last 30 minutes",
    es: "Datos en los últimos 30 minutos",
    pt: "Dados nos últimos 30 minutos",
  },
  noReply: {
    en: "Unanswered",
    es: "Sin respuesta",
    pt: "Sem resposta",
  },
  title: {
    en: "Status monitor",
    es: "Monitor de status",
    pt: "Monitor de status",
  },
  lastUpdated: {
    en: "Last update",
    es: "Última actualización",
    pt: "Última atualização",
  },
  percentile95: {
    en: "95th percentile",
    es: "Percentil 95",
    pt: "Percentil 95",
  },
  subTitle: {
    en: "This status monitor tests the bot on each channel with a message every 1 minute, confirming response and time.",
    es: "Este monitor de status prueba el bot en cada canal con un mensaje cada 1 minuto, confirmando respuesta y lapso.",
    pt: "Este monitor de status testa o bot em cada canal com uma mensagem a cada 1 minuto, confirmando a resposta e o tempo.",
  },
  recentLogs: {
    en: "Recent logs",
    es: "Logs recientes",
    pt: "Logs recentes",
  },
  reply: {
    en: "replied in ",
    es: "respondió en ",
    pt: "respondeu em ",
  },
  seconds: {
    en: "seconds",
    es: "segundos",
    pt: "segundos",
  },
  secs: {
    en: "secs",
    es: "segs",
    pt: "segs",
  },
  statusOperative: {
    en: "Operating",
    es: "Operando",
    pt: "Operativo",
  },
  statusUnstable: {
    en: "Unstable",
    es: "Inestable",
    pt: "Instável",
  },
  statusDelays: {
    en: "With delay",
    es: "Con demora",
    pt: "Com atraso",
  },
  uptimeOK: {
    en: "OK",
    es: "OK",
    pt: "OK",
  },
  uptimeNOK: {
    en: "With issues",
    es: "Con problemas",
    pt: "Com problemas",
  },
  metric_user: {
    en: "Latency to process an incoming message from the user",
    es: "Latencia para procesar un mensaje entrante del usuario",
    pt: "Latência para processar uma mensagem de entrada do usuário",
  },
  "metric_operator-msg": {
    en: "Latency from agent sending a message until WhatsApp is reached",
    es: "Latencia desde que un agente escribe hasta que WhatsApp es alcanzado",
    pt: "Latência desde que um agente escreve até que o WhatsApp seja alcançado",
  },
  noMetrics: {
    en: "No metrics found to display",
    es: "No se encontraron metricas para mostrar",
    pt: "Nenhuma métrica encontrada para exibição",
  },
  "metric_api-send-message": {
    en: "Latency from calling the Botmaker API until WhatsApp is reached",
    es: "Latencia desde que se llama a la API de Botmaker hasta que se alcanza WhatsApp",
    pt: "Latência desde a chamada da API do Botmaker até que o WhatsApp seja alcançado",
  },
  "metric_web-console-get-customers": {
    en: "Health check for the client list assigned to the agents",
    es: "Chequeo de salud para la lista de clientes asignada a los agentes",
    pt: "Verificação de saúde para a lista de clientes atribuída aos agentes",
  },
  "metric_web-console-get-customers_desc": {
    en: "Monitor the status of the client list assigned to the agents within the platform.",
    es: "Monitorea el estado de la lista de clientes que se asigna a los agentes dentro de la plataforma.",
    pt: "Monitore o status da lista de clientes atribuída aos agentes dentro da plataforma.",
  },
  "metric_webhook-calls-status": {
    en: "Webhook usage",
    es: "Uso de webhook",
    pt: "Uso de webhook",
  },
  "metric_webhook-calls-status_desc": {
    en: "Quantity of webhook calls that are being executed at this time",
    es: "Cantidad de llamadas de webhook que se están ejecutando en este momento",
    pt: "Quantidade de chamadas de webhook que estão sendo executadas neste momento",
  },
  "webhook-calls-status-err": {
    en: "Webhook errors",
    es: "Errores de webhook",
    pt: "Erros de webhook",
  },
  "webhook-calls-status-err_desc": {
    en: "Quantity of webhook calls that are being executed and failed at this time",
    es: "Cantidad de llamadas de webhook que se están ejecutando y fallaron en este momento",
    pt: "Quantidade de chamadas de webhook que estão sendo executadas e falharam neste momento",
  },
  wap_sent_ok: {
    en: "WhatsApp sent messages",
    es: "Mensajes enviados por WhatsApp",
    pt: "Mensagens enviadas pelo WhatsApp",
  },
  wap_sent_ok_desc: {
    en: "Quantity of WhatsApp messages that are being sent at this time",
    es: "Cantidad de mensajes de WhatsApp que se están enviando en este momento",
    pt: "Quantidade de mensagens do WhatsApp que estão sendo enviadas neste momento",
  },
  wap_sent_failed: {
    en: "WhatsApp sent messages with problems",
    es: "Mensajes enviados por WhatsApp con problemas",
    pt: "Mensagens enviadas pelo WhatsApp com problemas",
  },
  wap_sent_failed_desc: {
    en: "Quantity of WhatsApp messages that are being sent and failed at this time",
    es: "Cantidad de mensajes de WhatsApp que se están enviando y fallaron en este momento",
    pt: "Quantidade de mensagens do WhatsApp que estão sendo enviadas e falharam neste momento",
  },
  "status_wapp_mon-user": {
    en: "Quantity of WhatsApp messages received from users",
    es: "Cantidad de mensajes de WhatsApp recibidos por usuarios",
    pt: "Quantidade de mensagens do WhatsApp recebidas de usuários",
  },
  "status_wapp_mon-user_desc": {
    en: "Monitor how many WhatsApp messages have been received from users on the platform in real time.",
    es: "Controla cuántos mensajes de WhatsApp han sido recibidos de los usuarios en la plataforma en tiempo real.",
    pt: "Monitore quantas mensagens do WhatsApp foram recebidas de usuários na plataforma em tempo real.",
  },
  "metric_status_wapp_mon-user": {
    en: "Quantity of WhatsApp messages received from users",
    es: "Cantidad de mensajes de WhatsApp recibidos por usuarios",
    pt: "Quantidade de mensagens do WhatsApp recebidas de usuários",
  },
  "metric_status_wapp_mon-user_desc": {
    en: "Monitor how many WhatsApp messages have been received from users on the platform in real time.",
    es: "Controla cuántos mensajes de WhatsApp han sido recibidos de los usuarios en la plataforma en tiempo real.",
    pt: "Monitore quantas mensagens do WhatsApp foram recebidas de usuários na plataforma em tempo real.",
  },
  "status_wapp_mon-api-send-message": {
    en: "Quantity of WhatsApp messages sent using the Botmaker API",
    es: "Cantidad de mensajes enviados de WhatsApp usando la API de Botmaker",
    pt: "Quantidade de mensagens do WhatsApp enviadas usando a API do Botmaker",
  },
  "status_wapp_mon-api-send-message_desc": {
    en: "Monitor the quantity of WhatsApp messages that are being sent through the Botmaker API in real time.",
    es: "Supervisa la cantidad de mensajes de WhatsApp que se envían a través de la API de Botmaker en tiempo real.",
    pt: "Monitore a quantidade de mensagens do WhatsApp que estão sendo enviadas através da API do Botmaker em tempo real.",
  },
  "metric_status_wapp_mon-api-send-message": {
    en: "Quantity of WhatsApp messages sent using the Botmaker API",
    es: "Cantidad de mensajes enviados de WhatsApp usando la API de Botmaker",
    pt: "Quantidade de mensagens do WhatsApp enviadas usando a API do Botmaker",
  },
  "metric_status_wapp_mon-api-send-message_desc": {
    en: "Monitor the quantity of WhatsApp messages that are being sent through the Botmaker API in real time.",
    es: "Supervisa la cantidad de mensajes de WhatsApp que se envían a través de la API de Botmaker en tiempo real.",
    pt: "Monitore a quantidade de mensagens do WhatsApp que estão sendo enviadas através da API do Botmaker em tempo real.",
  },
  "status_wapp_mon-operator-msg": {
    en: "Quantity of WhatsApp sent messages by agents",
    es: "Cantidad de mensajes de WhatsApp enviados por agentes",
    pt: "Quantidade de mensagens do WhatsApp enviadas por agentes",
  },
  "status_wapp_mon-operator-msg_desc": {
    en: "Quantity of WhatsApp messages that are being sent by agents at this time.",
    es: "Supervisa la cantidad de mensajes de WhatsApp que los agentes están enviando actualmente a través de la plataforma Botmaker.",
    pt: "Quantidade de mensagens do WhatsApp que estão sendo enviadas por agentes neste momento.",
  },
  "metric_status_wapp_mon-operator-msg": {
    en: "Quantity of WhatsApp sent messages by agents",
    es: "Cantidad de mensajes de WhatsApp enviados por agentes",
    pt: "Quantidade de mensagens do WhatsApp enviadas por agentes",
  },
  "metric_status_wapp_mon-operator-msg_desc": {
    en: "Quantity of WhatsApp messages that are being sent by agents at this time.",
    es: "Supervisa la cantidad de mensajes de WhatsApp que los agentes están enviando actualmente a través de la plataforma Botmaker.",
    pt: "Quantidade de mensagens do WhatsApp que estão sendo enviadas por agentes neste momento.",
  },
  "metric_webhook-calls-status-error": {
    en: "Webhook error",
    es: "Error de webhook",
    pt: "Erro de webhook",
  },
  "metric_api-get-chats": {
    en: "Botmaker API health",
    es: "Estado de salud de Botmaker API",
    pt: "Saúde da API do Botmaker",
  },
  "metric_api-get-chats_desc": {
    en: "Health check of Botmaker API using a REST client.",
    es: "Chequeo de salud de API de Botmaker utilizando un cliente REST.",
    pt: "Verificação de saúde da API do Botmaker usando um cliente REST.",
  },
  "metric_webchat-user-message": {
    en: "Webchat",
    es: "Webchat",
    pt: "Webchat",
  },
  "metric_web-console-access": {
    en: "Quantity of agents accessing the platform",
    es: "Cantidad de agentes accediendo a la plataforma",
    pt: "Quantidade de agentes acessando a plataforma",
  },
  "metric_web-console-access_desc": {
    en: "Control how many agents are accessing the Botmaker platform at any time.",
    es: "Controla cuántos agentes están accediendo a la plataforma de Botmaker en cualquier momento.",
    pt: "Controle quantos agentes estão acessando a plataforma do Botmaker a qualquer momento.",
  },
  "metric_web-console-get-messages": {
    en: "Quantity of messages retrieval for agents",
    es: "Cantidad de mensajes para agentes",
    pt: "Quantidade de mensagens para agentes",
  },
  "metric_web-console-get-messages_desc": {
    en: "Health check for clients getting messages in the platform.",
    es: "Monitorea la salud y el flujo de los mensajes recibidos por los agentes dentro de la plataforma de Botmaker.",
    pt: "Verificação de saúde para clientes recebendo mensagens na plataforma.",
  },
  wappApiCallOK: {
    en: "Quantity of WhatsApp API calls per second",
    es: "Cantidad de llamadas a la API de WhatsApp por segundo",
    pt: "Quantidade de chamadas da API do WhatsApp por segundo",
  },
  wappApiCallFailed: {
    en: "With errors",
    es: "Con errores",
    pt: "Com erros",
  },
  webchatIncomeProcessing: {
    en: "Webchat sum of incoming messages by second",
    es: "Webchat suma de mensajes entrantes por segundo",
    pt: "Webchat soma de mensagens recebidas por segundo",
  },
  agentAccessTime: {
    en: "Agent access latency to the platform",
    es: "Latencia de acceso del agente a la plataforma",
    pt: "Latência de acesso do agente à plataforma",
  },
  agentAccessTime_desc: {
    en: "Measurement of the total latency it takes for current agents to access the platform by going to go.botmaker.com.",
    es: "Medición de la latencia total que les toma a los agentes actuales acceder a la plataforma mediante go.botmaker.com.",
    pt: "Medição da latência total que os agentes atuais levam para acessar a plataforma indo para go.botmaker.com.",
  },
  "metric_agent-access-time": {
    en: "Agent access latency to the platform",
    es: "Latencia de acceso del agente a la plataforma",
    pt: "Latência de acesso do agente à plataforma",
  },
  "metric_agent-access-time_desc": {
    en: "Measurement of the total latency it takes for current agents to access the platform by going to go.botmaker.com.",
    es: "Medición de la latencia total que les toma a los agentes actuales acceder a la plataforma mediante go.botmaker.com.",
    pt: "Medição da latência total que os agentes atuais levam para acessar a plataforma indo para go.botmaker.com.",
  },
  agentGetMessageTime: {
    en: "Real-time message latency",
    es: "Latencia de mensajes en tiempo real",
    pt: "Latência de mensagens em tempo real",
  },
  agentGetMessageTime_desc: {
    en: "Monitor the latency of messages from the user to the platform in real time.",
    es: "Monitorea la latencia de los mensajes del usuario a la plataforma en tiempo real.",
    pt: "Monitore a latência das mensagens do usuário até a plataforma em tempo real.",
  },
  "metric_agent-get-message-time": {
    en: "Real-time message latency",
    es: "Latencia de mensajes en tiempo real",
    pt: "Latência de mensagens em tempo real",
  },
  "metric_agent-get-message-time_desc": {
    en: "Monitor the latency of messages from the user to the platform in real time.",
    es: "Monitorea la latencia de los mensajes del usuario a la plataforma en tiempo real.",
    pt: "Monitore a latência das mensagens do usuário até a plataforma em tempo real.",
  },
  queueWaiting: {
    en: "Approximate quantity of users waiting in all queues",
    es: "Cantidad promedio de usuarios en colas de atención",
    pt: "Quantidade aproximada de usuários em todas as filas de espera",
  },
  queueWaiting_desc: {
    en: "Track the average quantity of users waiting to be connected with a human agent in real time.",
    es: "Rastrea la cantidad promedio de usuarios esperando ser conectados con un agente humano en tiempo real.",
    pt: "Rastreie a quantidade média de usuários esperando ser conectados a um agente humano em tempo real.",
  },
  "metric_status-queue-waiting": {
    en: "Approximate quantity of users waiting in all queues",
    es: "Cantidad promedio de usuarios en colas de atención",
    pt: "Quantidade aproximada de usuários em todas as filas de espera",
  },
  "metric_status-queue-waiting_desc": {
    en: "Track the average quantity of users waiting to be connected with a human agent in real time.",
    es: "Rastrea la cantidad promedio de usuarios esperando ser conectados con un agente humano en tiempo real.",
    pt: "Rastreie a quantidade média de usuários esperando ser conectados a um agente humano em tempo real.",
  },
  queueWaitingSecs: {
    en: "Approximate time users wait for human agent",
    es: "Tiempo aproximado que los usuarios esperan por un agente humano",
    pt: "Tempo aproximado que os usuários esperam por um agente humano",
  },
  queueWaitingSecs_desc: {
    en: "Track the average time a user spends waiting to be connected with a human agent in real time.",
    es: "Rastrea el tiempo promedio que un usuario pasa esperando hasta ser conectado con un agente humano en tiempo real.",
    pt: "Acompanhe o tempo médio que um usuário passa esperando para ser conectado a um agente humano em tempo real.",
  },
  "metric_status-queue-waiting-secs": {
    en: "Approximate time users wait for human agent",
    es: "Tiempo aproximado que los usuarios esperan por un agente humano",
    pt: "Tempo aproximado que os usuários esperam por um agente humano",
  },
  "metric_status-queue-waiting-secs_desc": {
    en: "Track the average time a user spends waiting to be connected with a human agent in real time.",
    es: "Rastrea el tiempo promedio que un usuario pasa esperando hasta ser conectado con un agente humano en tiempo real.",
    pt: "Acompanhe o tempo médio que um usuário passa esperando para ser conectado a um agente humano em tempo real.",
  },
  onlineAgents: {
    en: "Approximate quantity of agents online",
    es: "Cantidad promedio de agentes en línea",
    pt: "Quantidade aproximada de agentes online",
  },
  onlineAgents_desc: {
    en: "Monitor an estimate of the active agents on the platform.",
    es: "Monitorea una estimación de los agentes activos en la plataforma.",
    pt: "Monitore uma estimativa dos agentes ativos na plataforma.",
  },
  "metric_online-agents": {
    en: "Approximate quantity of agents online",
    es: "Cantidad promedio de agentes en línea",
    pt: "Quantidade aproximada de agentes online",
  },
  "metric_online-agents_desc": {
    en: "Monitor an estimate of the active agents on the platform.",
    es: "Monitorea una estimación de los agentes activos en la plataforma.",
    pt: "Monitore uma estimativa dos agentes ativos na plataforma.",
  },
  noMonitor: {
    en: "Your bot is not currently being monitored, please contact the support team if you are interested in activating it.",
    es: "Tu bot no está siendo monitoreado actualmente, por favor contacta al equipo de soporte si estás interesado en activarlo.",
    pt: "Seu bot não está sendo monitorado no momento. Entre em contato com a equipe de suporte se estiver interessado em ativá-lo.",
  },
  loading: {
    en: "Loading...",
    es: "Cargando...",
    pt: "Carregando...",
  },
  footer_copyright: {
    en: "Copyright © " + YEAR + " - All rights reserved.",
    es: "Copyright © " + YEAR + " - Todos los derechos reservados.",
    pt: "Copyright © " + YEAR + " - Todos os direitos reservados.",
  },
  footer_status: {
    en: "Platform status",
    es: "Estado de la plataforma",
    pt: "Status da plataforma",
  },
  footer_privacy_policy: {
    en: "Privacy Policy",
    es: "Política de privacidad",
    pt: "Política de privacidade",
  },
  header_main: {
    en: "Platform status",
    es: "Estado de la plataforma",
    pt: "Status da plataforma",
  },
  header_see_history: {
    en: "Maintenance",
    es: "Mantenimientos",
    pt: "Manutenção",
  },
  header_change_logs: {
    en: "Changelog",
    es: "Historial de cambios",
    pt: "Registros de alterações",
  },
  header_mobile_menu: {
    en: "Menu",
    es: "Menú",
    pt: "Menu",
  },
  chart_no_issues: {
    en: "No issues",
    es: "Sin problemas",
    pt: "Sem problemas",
  },
  charts_title_platforms_states: {
    en: "Platform status",
    es: "Estado de la plataforma",
    pt: "Status da plataforma",
  },
  chart_error_state_ok: {
    en: "Working",
    es: "Funcionando",
    pt: "Funcionando",
  },
  chart_error_state_warning: {
    en: "Partial issues",
    es: "Problemas parciales",
    pt: "Problemas parciais",
  },
  chart_error_state_error: {
    en: "Interruption",
    es: "Interrumpido",
    pt: "Interrompido",
  },
  status_header_ok_title: {
    en: "All systems are operational",
    es: "Todos los sistemas están operativos",
    pt: "Todos os sistemas estão operacionais",
  },
  status_header_warning_title: {
    en: "Some cases present technical problems",
    es: "Algunos casos presentan problemas técnicos",
    pt: "Alguns casos apresentam problemas técnicos",
  },
  status_header_error_title: {
    en: "Some cases present technical problems",
    es: "Algunos casos presentan problemas técnicos",
    pt: "Alguns casos apresentam problemas técnicos",
  },
  status_header_warning_substatus: {
    en: "We are experiencing inconveniences in ",
    es: "Estamos experimentando inconvenientes en ",
    pt: "Estamos enfrentando transtornos em ",
  },
  status_header_error_substatus: {
    en: "We are experiencing interruptions in ",
    es: "Estamos experimentando interrupciones en ",
    pt: "Estamos enfrentando interrupções em ",
  },
  and: {
    en: "and",
    es: "y",
    pt: "e",
  },
  status_header_last_update: {
    en: "Last update: ",
    es: "Última actualización: ",
    pt: "Última atualização: ",
  },
  status_header_btn_suscribe: {
    en: "Subscribe to system updates",
    es: "Suscribirse a actualizaciones de sistema",
    pt: "Inscreva-se para atualizações do sistema",
  },
  status_header_btn_suscribed: {
    en: "Subscribed to notifications",
    es: "Suscrito a notificaciones",
    pt: "Inscrito para notificações",
  },
  status_header_brn_report: {
    en: "Report an issue",
    es: "Reportar un problema",
    pt: "Relatar um problema",
  },
  btn_back: {
    en: "Back",
    es: "Volver",
    pt: "Voltar",
  },
  history_title: {
    en: "History",
    es: "Historial",
    pt: "Histórico",
  },
  history_subtitle: {
    en: "Maintenance records.",
    es: "Registro de mantenimientos realizados.",
    pt: "Registro de manutenções realizadas.",
  },
  history_no_entries: {
    en: "No entries found",
    es: "No se encontraron entradas",
    pt: "Nenhuma entrada encontrada",
  },
  logs_subtitle: {
    en: "All updates and improvements made.",
    es: "Registro de todas las actualizaciones y mejoras realizadas.",
    pt: "Registro de todas as atualizações e melhorias realizadas.",
  },
  header_affected_features: {
    en: "Affected features",
    es: "Features afectadas",
    pt: "Recursos afetados",
  },
  header_read_incident: {
    en: "Read maintenance",
    es: "Leer mantenimiento",
    pt: "Ler manutenção",
  },
  history_summary_issue: {
    en: "Issue summary:",
    es: "Resumen del problema:",
    pt: "Resumo do problema:",
  },
  languages_en: {
    en: "English",
    es: "Inglés",
    pt: "Inglês",
  },
  languages_es: {
    en: "Spanish",
    es: "Español",
    pt: "Espanhol",
  },
  languages_pt: {
    en: "Portuguese",
    es: "Portugués",
    pt: "Português",
  },
  tooltip_segundos: {
    en: "Seconds",
    es: "Segundos",
    pt: "Segundos",
  },
  tooltip_agentes: {
    en: "Agents",
    es: "Agentes",
    pt: "Agentes",
  },
  tooltip_usuarios: {
    en: "Users",
    es: "Usuarios",
    pt: "Usuários",
  },
  tooltip_mensajes: {
    en: "Messages",
    es: "Mensajes",
    pt: "Mensagens",
  },
  tooltip_no_data: {
    en: "No data",
    es: "Sin medición",
    pt: "Sem medição",
  },
  view_details: {
    en: "View details",
    es: "Ver detalles",
    pt: "Ver detalhes",
  },
  "graph_label_agent-access-time": {
    es: "Tiempo de acceso en segundos cada 5 minutos.",
    en: "Access time in seconds every 5 minutes.",
    pt: "Tempo de acesso em segundos a cada 5 minutos.",
  },
  "graph_label_agent-get-message-time": {
    es: "Tiempo de latencia en segundos cada 5 minutos.",
    en: "Latency time in seconds every 5 minutes.",
    pt: "Tempo de latência em segundos a cada 5 minutos.",
  },
  "graph_label_online-agents": {
    es: "Cantidad de agentes promedio cada 5 minutos.",
    en: "Average number of agents every 5 minutes.",
    pt: "Número médio de agentes a cada 5 minutos.",
  },
  "graph_label_status-queue-waiting": {
    es: "Cantidad de usuarios promedio cada 5 minutos.",
    en: "Average number of users every 5 minutes.",
    pt: "Número médio de usuários a cada 5 minutos.",
  },
  "graph_label_status-queue-waiting-secs": {
    es: "Tiempo de espera en segundos cada 5 minutos.",
    en: "Waiting time in seconds every 5 minutes.",
    pt: "Tempo de espera em segundos a cada 5 minutos.",
  },
  "graph_label_status_wapp_mon-api-send-message": {
    es: "Cantidad de mensajes enviados cada 5 minutos.",
    en: "Number of messages sent every 5 minutes.",
    pt: "Número de mensagens enviadas a cada 5 minutos.",
  },
  "graph_label_status_wapp_mon-operator-msg": {
    es: "Cantidad de mensajes enviados por agente cada 5 minutos.",
    en: "Number of messages sent by agent every 5 minutes.",
    pt: "Número de mensagens enviadas por agente a cada 5 minutos.",
  },
  "graph_label_status_wapp_mon-user": {
    es: "Cantidad de mensajes recibidos por usuarios cada 5 minutos.",
    en: "Number of messages received by users every 5 minutes.",
    pt: "Número de mensagens recebidas por usuários a cada 5 minutos.",
  },
  "graph_label_web-console-access": {
    es: "Cantidad de agentes por minuto.",
    en: "Number of agents per minute.",
    pt: "Número de agentes por minuto.",
  },
  "graph_label_web-console-get-customers": {
    es: "Cantidad de usuarios por minuto.",
    en: "Number of users per minute.",
    pt: "Número de usuários por minuto.",
  },
  "graph_label_web-console-get-messages": {
    es: "Cantidad de mensajes para agentes por minuto.",
    en: "Number of messages for agents per minute.",
    pt: "Número de mensagens para agentes por minuto.",
  },
  "back_to_24hs": {
    en: "Back to 24 hours",
    es: "Volver a 24 horas",
    pt: "Voltar para 24 horas",
  },
  "click_more_details": {
    en: "Click for more details",
    es: "Haz click para más detalles",
    pt: "Clique para mais detalhes",
  },
  "tooltip_avg": {
    es: "Promedio de",
    en: "Average of",
    pt: "Média de",
  },
  "metric_gchat_channel_test": {
    en: "Google Chats channel health",
    es: "Estado de salud del canal de Google Chats",
    pt: "Saúde do canal de Google Chats",
  },
  "metric_gchat_channel_test_desc": {
    en: "Health check of Google Chats channel using an automated test.",
    es: "Chequeo de salud del canal de Google Chats usando una prueba automatizada.",
    pt: "Verificação de saúde do canal de Google Chats usando um teste automatizado.",
  },
  "metric_webchat_status_checker": {
    en: "Webchat health",
    es: "Estado de salud del Webchat",
    pt: "Saúde do Webchat",
  },
  "metric_webchat_status_checker_desc": {
    en: "Health check of Webchat using an automated test.",
    es: "Chequeo de salud de Webchat usando una prueba automatizada.",
    pt: "Verificação de saúde do Webchat usando um teste automatizado.",
  },
  "metric_email_status_checker": {
    en: "Email health",
    es: "Estado de salud del Email",
    pt: "Saúde do Email",
  },
  "metric_email_status_checker_desc": {
    en: "Health check of Email using an automated test.",
    es: "Chequeo de salud de Email usando una prueba automatizada.",
    pt: "Verificação de saúde do Email usando um teste automatizado.",
  },
  charts_title_channels_states: {
    en: "Channels status",
    es: "Estado de los canales",
    pt: "Status dos canais",
  },
};
//mensaje enviado/respuesta recibida+despuesta de bot/email envia alerta/demora tanto segundos/link en los clientes que lo tienen

const getLang = () => {
  const browserLang =
    localStorage.getItem("language") ||
    navigator.language ||
    navigator.userLanguage ||
    "en-US";

  return browserLang.indexOf("-") === -1
    ? browserLang
    : browserLang.split("-")[0];
};

const l = (key, l) => {
  const theLang = l || getLang() || "en";
  const text = TEXTS[key];

  if (!text) return null;

  return text[theLang] || null;
};

exports.getLang = getLang;
exports.l = l;
